import news from '../../images'
import { about, blog, contact, guardian, homestay, workwithus, educationServices, supportServices } from "../../images";

export const aboutusSection = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '关于我们',
  headline: '我们是谁, 我们做什么',
  description: '了解 Study Links 的历史、我们当前的团队以及我们几十年来的成就',
  buttonLabel: '了解更多',

  buttonDestination: '/about',

  imgStart: false,
  img: about,
  alt: '关于我们',
  dark: false,
  primary: false,
  darkText: true
};

export const guardianshipSection = {
  id: 'guardianship',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '监护',
  headline: '您在寻找监护人吗？',
  description: '监护人是由学生父母选择的人，负责提供人文关怀和学业上的照顾，确保学生在英国学习期间的福祉和安全。监护人在合理要求的时间内代表父母行事。',
  buttonLabel: '了解更多',

  buttonDestination: '/guardianship',

  imgStart: true,
  img: guardian,
  alt: '监护',
  dark: true,
  primary: true,
  darkText: false
};

export const homestaySection = {
  id: 'homestay',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '家庭寄宿',
  headline: '您在寻找家庭寄宿吗？',
  description: '每年都有数千名在英国学习的年轻人成为一个新家庭的成员 - 一些人是短期的，另一些呆的时间会长些。但是每一个人都会留下回忆、拥有积极的体验并建立持久的情感纽带，多亏了那些无私的寄宿家庭，他们们在异乡为留学生们提供了家一般的呵护。',
  buttonLabel: '了解更多',
  buttonDestination: '/homestay',
  imgStart: false,
  img: homestay,
  alt: '家庭寄宿',
  dark: false,
  primary: false,
  darkText: true
};

export const educationServicesSection = {
  id: 'educationServices',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '教育服务',
  headline: '您在寻找教育支持吗？',
  description: 'Study Links 提供服务以支持、促进和加速学生的学业进步。我们的团队和留学生家庭密切合作，量身定制合适的支持方案，让学生能够充分发挥出他们的潜力。',
  buttonLabel: '了解更多',

  buttonDestination: '/educationServices',

  imgStart: true,
  img: educationServices,
  alt: '教育服务',
  dark: true,
  primary: true,
  darkText: false
};

export const supportServicesSection = {
  id: 'supportServices',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '支持服务',
  headline: '您需要支持才能自信地在国外生活吗？',
  description: '我们帮助学生及其家庭完成移居海外的每一步：从移民和安置服务到文化和礼仪培训。',
  buttonLabel: '了解更多',
  buttonDestination: '/supportServices',
  imgStart: false,
  img: supportServices,
  alt: '支持服务',
  dark: false,
  primary: false,
  darkText: true
};

export const courseSection = {
  id: 'course',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'UK Course Applications',
  headline: 'Applying for British school or university?',
  description: 'Since 1998, we have helped international students make successful applications to world leading British universities and the best boarding schools in the UK. We guide and advise students and parents so that you are well prepared to make informed decisions about institution and subject choices.',
  buttonLabel: 'UK education system: Explained',
  buttonLabel2: 'Secondary schools',
  buttonLabel3: 'Further education (FE) colleges',
  buttonLabel4: 'Universities',
  buttonLabel5: 'English language schools',
  buttonLabel6: 'Summer schools',
  buttonLabel7: 'Group study programmes',
  imgStart: true,
  img: blog,
  alt: 'Blog',
  dark: true,
  primary: true,
  darkText: false
};

export const newsSection = {
  id: 'news',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'News',
  headline: "Keep up to date with StudyLinks' response to COVID19",
  description: '',
  buttonLabel: 'Latest news',
  imgStart: false,
  img: news,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true
};

export const blogSection = {
  id: 'blog',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Blog',
  headline: 'Read about the latest goings-on at Study Links',
  description: 'See what our students have to say about their experiences so far!',
  buttonLabel: 'Read the blog',
  buttonDestination: '/blog',
  imgStart: true,
  img: blog,
  alt: 'blog',
  dark: true,
  primary: true,
  darkText: false
};

export const workSection = {
  id: 'workwithus',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Working with us',
  headline: 'We are always looking for great people to work with!',
  description: 'Find out more about how we can work together!',
  buttonLabel: 'Find out more',
  buttonDestination: '/workwithus',
  imgStart: false,
  img: workwithus,
  alt: 'work with us',
  dark: false,
  primary: false,
  darkText: true
};

export const contactSection = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '联系方式',
  headline: '联系我们',
  description: '通过社交媒体联系或提交查询',
  buttonLabel: '提交查询',
  buttonDestination: '/contact',
  imgStart: true,
  img: contact,
  alt: '联系方式',
  dark: true,
  primary: true,
  darkText: false
};

export const covidSection = {
  id: 'covid',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'COVID19',
  headline: 'Customer statement re. Covid-19',
  description: 'At this time, Study Links remains open and operational, and will continue to deliver the high quality and reliable service you are used to.',
  description_2: 'For as long as the Government permits it, it is our intention to remain open throughout. We remain vigilant to ensure the ongoing safety and wellbeing of our students and our workforce during this difficult time and have implemented appropriate safe working measures. Our clients and partners can be assured that we are doing everything in our power to provide a continuous service through these challenging times.',
  description_3: 'We will continue to monitor the evolving situation closely on a daily basis and will update our internal processes and statement as and when circumsatnces warrant it. If you have further questions or concerns, please contact your company representative.',
  // buttonLabel: 'Find a guardian',
  dark: true,
  primary: true,
  darkText: false
};