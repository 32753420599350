import styled from 'styled-components'

export const MenuContainer = styled.div`
  color: black;
  height: calc(1600px + 80vw);
  position: relative;
  display: flex;
  justify-content: center;

  @media(min-width: 820px) {
    height: 1400px;
  }
  @media(min-width: 1240px) {
    height: 1000px;
  }
`
