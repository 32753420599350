import React from 'react'
import { CardsItem, CardsItemLink, CardsItemPicWrap, CardsItemImg, CardsItemInfo, CardsItemText } from "./CardElements";

function ButtonCardItem(props) {
    return (
      <>
        <CardsItem>
          <CardsItemLink to={props.path}>
            <CardsItemPicWrap data-category={props.label}>
              <CardsItemImg
                alt='hi'
                src={props.src}
              />
            </CardsItemPicWrap>
            <CardsItemInfo>
              <CardsItemText>{props.text}</CardsItemText>
            </CardsItemInfo>
          </CardsItemLink>
        </CardsItem>
      </>
    );
  }

export default ButtonCardItem
