import * as styledC from "./NavbarElements";
import React, { useState, useEffect } from 'react'
import {ScrollAboutItem, ScrollGuardianshipItem, ScrollHomestayItem, ScrollBlogItem, ScrollWorkItem, ScrollContactItem, MobileIconItem, NavLogoItem, ScrollEducationItem, ScrollSupportItem} from "./NavBarItems"
import styled from 'styled-components'
import {Icons} from '../Footer/Social'


const CornerIconContainer = styled.div `
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const ScrollNavbar = ({toggle}) => {
  const [scrollNav, setScrollNav] = useState(false)
  useEffect(() => {
    const changeNav = () => {
      if (window.scrollY >= 0.1) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    }
    window.addEventListener('scroll', changeNav);
  }, []);

  return (
    <>
      <styledC.Nav scrollNav = {scrollNav}>
        <NavLogoItem />
        <styledC.NavbarContainer>
          <MobileIconItem toggle={toggle}/>
          <styledC.NavMenu>
            <ScrollAboutItem />
            <ScrollGuardianshipItem />
            <ScrollHomestayItem />
            <ScrollEducationItem/>
            <ScrollSupportItem/>
            <ScrollContactItem />
          </styledC.NavMenu>
          <CornerIconContainer>
              <Icons />
            </CornerIconContainer>
        </styledC.NavbarContainer>
      </styledC.Nav>
    </>
  )
}

export default ScrollNavbar