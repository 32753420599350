import React, {useState} from "react"
import { css } from "@emotion/react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { rhythm } from "../utils/typography"
import HeroSection from "../containers/Hero";
import ScrollNavbar from "../components/Navbar/ScrollNavbar";
import Footer from "../components/Footer"
import Menu from "../containers/Menu"
import '../styles/global.css'
import {HomepageTestimony, TestimonialCarousel} from '../components/Testimonials'
import VideoSection from "../containers/VideoSection"
import MobileNavbar from "../components/MobileNavBar";
import InfoSection from '../containers/InfoSection';
import { aboutusSection, guardianshipSection, homestaySection, courseSection, newsSection, blogSection, workSection, contactSection, covidSection, educationServicesSection, supportServicesSection } from '../containers/InfoSection/Data';
import { VerticalIcons } from "../components/Footer/Social";
import { support } from "jquery";
import { QRCodePopup } from "../components/WeChatPopup";
import SEO from "../components/seo";

export default function Home(props) {

  const {sidebar, children, bannerId, pageTitle} = props
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
        <SEO title="Home | Guardianship | British Education | Homestay" description="Study Links works alongside the best schools & other educational institutions in the UK ensuring the education provided is accessible and beneficial to the individuals under our care."/>
        <MobileNavbar isOpen={isOpen} toggle={toggle}/>
        <ScrollNavbar toggle={toggle}/>
        <QRCodePopup/>

        {/* <VerticalIcons /> */}
        <HeroSection/>
        <VideoSection/>
        {/* <Menu /> */}
        <InfoSection {...aboutusSection}/>
        <InfoSection {...guardianshipSection}/>
        <InfoSection {...homestaySection}/>
        <InfoSection {...educationServicesSection}/>
        <InfoSection {...supportServicesSection}/>
        <InfoSection {...contactSection}/>
        <HomepageTestimony />
        <Footer />
    </>
   
  )
}

