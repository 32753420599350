import Video from "../../components/Video/Video";
import React, {useState} from 'react'
import { VideoSectionContainer, VideoContainer, Welcome, WelcomeTitle, WelcomeText, ButtonWrapper, VideoSectionWrapper } from "./elements";
import { ScrollButton } from "../../components/ButtonElement";
import { ArrowDownThick, ArrowDownward } from "../Hero/HeroElements";
import WelcomeVideo from '../../video/Welcome.mp4'

const VideoSection = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }
  return (
    <VideoSectionContainer id='videoSection'>
      <VideoSectionWrapper>
        <Welcome>
          <WelcomeTitle>
          欢迎来到 Study Links
          </WelcomeTitle>
          <WelcomeText>
          Study Links 与英国最好的学校和其他教育机构合作，以确保所提供的教育对于我们所照顾的个人来说是可及且有益的。
          </WelcomeText>
          <WelcomeText>
          通往成功的道路有很多 - 为了确保每个学生的独特需求得到满足，我们在英国各地提供一系列高质量的服务。
          </WelcomeText>
        </Welcome>
        <VideoContainer>
          <Video videoSrcURL={WelcomeVideo} videoTitle="Welcome to Studylinks"/>
        </VideoContainer>
      </VideoSectionWrapper>
        <ButtonWrapper>
          <ScrollButton 
            to="about" 
            smooth={true}
            onMouseEnter={onHover} 
            onMouseLeave={onHover}
            primary = 'true'
            dark='true'
          >
            发现更多 {hover ? <ArrowDownThick /> : <ArrowDownward />}
          </ScrollButton>
        </ButtonWrapper>
    </VideoSectionContainer>
  )
}

export default VideoSection